import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArrowNavigationService {
  handleKeyDown(event: KeyboardEvent): void {
    const currentTab = document.activeElement as HTMLElement;
    const tabItems = Array.from(document.querySelectorAll('ul[ngbNav] a[ngbNavLink]'));

    // Check if the current element is a tab item or a link within tab content
    if (!tabItems.includes(currentTab) && !currentTab.closest('.tab-content')) {
      return; // Exit if the active element is neither a tab nor a link in tab content
    }

    const currentIndex = tabItems.indexOf(currentTab);
    let newTabIndex;

    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault(); // Prevent default behavior
        newTabIndex = (currentIndex + 1) % tabItems.length; // Move to next tab
        break;

      case 'ArrowLeft':
        event.preventDefault(); // Prevent default behavior
        newTabIndex = (currentIndex - 1 + tabItems.length) % tabItems.length; // Move to previous tab
        break;

      case 'Home':
        event.preventDefault(); // Prevent default behavior
        newTabIndex = 0; // Focus the first tab
        break;

      case 'End':
        event.preventDefault(); // Prevent default behavior
        newTabIndex = tabItems.length - 1; // Focus the last tab
        break;

      case 'Tab':
        if (event.shiftKey) {
          // Handle Shift + Tab
          event.preventDefault(); // Prevent default behavior
          // Check if the current element is one of the tab items
          if (tabItems.includes(currentTab)) {
            // Find the last item on the global sub-navigation
            const globalSubnavItems = document.querySelectorAll('.global-subnav a');
            const lastGlobalSubnavItem = globalSubnavItems[globalSubnavItems.length - 1] as HTMLElement;
            if (lastGlobalSubnavItem) {
              lastGlobalSubnavItem.focus(); // Focus the last global subnav item
            }
          } else {
            this.focusFirstContentElement(); // Move focus to the first focusable element in the active tab's content
          }
        } else {
          event.preventDefault(); // Prevent default tab behavior
          this.focusFirstContentElement(); // Move focus to the first focusable element in the active tab's content
        }
        break;
    }

    // Set focus on the new tab's <a> element, if applicable
    if (newTabIndex !== undefined) {
      (tabItems[newTabIndex] as HTMLElement).focus();
    }
  }

  // Function to focus the first content element
  private focusFirstContentElement(): void {
    const activeTabContent = document.querySelector('.tab-content') as HTMLElement;
    if (activeTabContent) {
      const firstFocusableElement = activeTabContent.querySelector('a, button, input, [tabindex="0"]') as HTMLElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }
  shiftToNavParent(){
    const selectedElement = document.querySelector('.tab-content a')
    console.log(`selectedElement`, selectedElement)
  }
}
