<bento-alert [items]="alerts"></bento-alert>
<main
  id="MainContent"
  tabindex="-1"
  role="main"
  class="Main create-view-main"
  *ngIf="!openPPG"
  [bentoBusyLoader]="isInputPanelDataLoading && isDataLoading"
>
  <div class="Main-body FilterPanel FilterPanel--toggle" [ngClass]="{'is-open': isFilterPanelOpen == true}">
    <aside class="FilterPanel-control">
      <header class="FilterPanel-header">
        <h1 class="h3 CreatView-FilterPanel-heading">
          <ng-container *ngIf="isCopyEditRequest || isCustomRequest"> Edit a view </ng-container>
          <ng-container *ngIf="!isCopyEditRequest && !isCustomRequest"> Create a view </ng-container>
        </h1>
        <button
          *ngIf="filterPanelFlag"
          type="button"
          class="btn btn-outline-secondary btn-icon btn-sm"
          [attr.aria-label]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
          [ngbTooltip]="isFilterPanelOpen ? 'Close filter panel' : 'Open filter panel'"
          placement="{{ isFilterPanelOpen ? 'left' : 'right' }}"
          (click)="onClickFilter(true)"
        >
          <i
            [ngClass]="isFilterPanelOpen ? 'bento-icon-double-arrow-left' : 'bento-icon-double-arrow-right'"
            aria-hidden="true"
          ></i>
        </button>
      </header>
      <p class="FilterPanel-description">
        To create a new view, select from the criteria below; upon selection, the data to the right will auto-populate.
      </p>
      <p class="label-required-summary padding-top-right no-bottom-margin">Required field</p>
      <form name="createView" #createView="ngForm" bfmForm (reset)="clearAll()" *ngIf="!isInputPanelDataLoading">
        <div class="FilterPanel-body no-top-padding">
          <div class="form-group Form-group">
            <fieldset class="Form-fieldset">
              <legend class="h5 Form-legend">Date range</legend>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  name="timeRangeType"
                  id="timeRangeType-rolling"
                  class="form-check-input"
                  [(ngModel)]="createViewsModel.isRollingTimeRange"
                  (ngModelChange)="onDateRangeChange()"
                  [value]="true"
                  [disabled]="isDataLoading"
                  aria-labelledby="DateRangeRollingLabel"
                />
                <label class="form-check-label" for="timeRangeType-rolling" id="DateRangeRollingLabel">Rolling</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  name="timeRangeType"
                  id="timeRangeType-fixed"
                  class="form-check-input"
                  [(ngModel)]="createViewsModel.isRollingTimeRange"
                  (ngModelChange)="onDateRangeChange()"
                  [value]="false"
                  [disabled]="isDataLoading"
                  aria-labelledby="DateRangeFixedLabel"
                />
                <label for="timeRangeType-fixed" class="form-check-label" id="DateRangeFixedLabel">Fixed</label>
              </div>
            </fieldset>
          </div>
          <div class="form-group Form-group" *ngIf="createViewsModel.isRollingTimeRange">
            <label for="timeRange" id="timeRangeLabel" class="bento-label-required">Time range</label>
            <bento-combobox
              name="timeRange"
              id="timeRange"
              bfmField
              bfmLabel="Time range"
              required
              [ngModel]="createViewsModel.rollingTimeRangeSelectedItems"
              (ngModelChange)="timeRangeOnItemsSelected($event)"
              [itemsObservable]="rollingTimeRangeItemsStream"
              [placeholder]="false"
              [options]="comboboxOptions"
              [rowTemplate]="timeRangeTemplate"
              [disabled]="isDataLoading"
              aria-labelledby="timeRangeLabel"
            ></bento-combobox>
            <ng-template #timeRangeTemplate let-$data="data" let-$options="options" let-$index="index">
              <div class="bento-list-item bento-combobox-item">
                {{ $data.row.name }}
              </div>
            </ng-template>
          </div>
          <div class="form-group FormRange" *ngIf="createViewsModel.timeRangeSelected == 'Fixed'">
            <div class="FormRange-group">
              <div class="FormRange-item FormRange-item--input">
                <label for="startDate" id="timeRangeStartLabel">
                  <div class="bento-label-required">Start date</div>
                  <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
                </label>
                <input
                  type="text"
                  name="startDate"
                  id="startDate"
                  class="form-control"
                  bfmField
                  bfmLabel="Start date"
                  required
                  [(ngModel)]="createViewsModel.fixedTimeRange.startDateText"
                  (ngModelChange)="handleFixedDateRangeSelection()"
                  [pattern]="validatePattern"
                  [validations]="validations.validatePattern"
                  (fieldValidate)="isValidDate($event, 'StartDate', createView)"
                  [disabled]="isDataLoading"
                  aria-labelledby="timeRangeStartLabel"
                />
              </div>
              <div class="FormRange-item FormRange-item--input">
                <label for="endDate" id="timeRangeEndLabel">
                  <div class="bento-label-required">End date</div>
                  <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
                </label>
                <input
                  type="text"
                  name="endDate"
                  #endDate
                  id="endDate"
                  class="form-control"
                  bfmLabel="End date"
                  bfmField
                  required
                  [(ngModel)]="createViewsModel.fixedTimeRange.endDateText"
                  (ngModelChange)="handleFixedDateRangeSelection()"
                  [pattern]="validatePattern"
                  [validations]="validations.validatePattern"
                  (fieldValidate)="isValidDate($event, 'EndDate', createView)"
                  [disabled]="isDataLoading"
                  aria-labelledby="timeRangeEndLabel"
                />
              </div>
            </div>
          </div>
          <div class="form-group Form-group">
            <label for="metric" id="metricsLabel" class="bento-label-required">Metrics (up to 25)</label>
            <app-bento-custom-multiselect-overlay
              name="metric"
              #metric
              id="metric"
              class="FormMultiselect FormMultiselect--extend"
              bfmLabel="Metrics"
              bfmField
              ngDefaultControl
              required
              [overlayMaxHeight]="468"
              [ngModel]="createViewsModel.metricSelectedItems"
              (ngModelChange)="metricOnItemsSelected($event)"
              [itemsObservable]="metricItemsStream"
              [editButtonText]="createViewsModel.metricSelectedText"
              [listItemTemplate]="metricItemTemplate"
              [disabled]="isDataLoading"
              [maxSelectedItems]="25"
              aria-labelledby="metricsLabel"
              [ariaLabelValue]="'metrics'"
            ></app-bento-custom-multiselect-overlay>
            <ng-template #metricItemTemplate let-item="item" let-index="index">
              <div *ngIf="item.groupId == null">
                <strong>{{ item.name }}</strong>
              </div>
              <div *ngIf="item.groupId != null">
                {{ item.name }}
              </div>
            </ng-template>
          </div>
          <div class="form-group Form-group">
            <label for="peerGroup" id="peerGroupLabel" class="bento-label-required">Peer groups (up to 4)</label>
            <app-bento-custom-multiselect-overlay
              name="peerGroup"
              #peerGroup
              id="peerGroup"
              class="FormMultiselect"
              bfmLabel="Peer group"
              bfmField
              ngDefaultControl
              required
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.peerGroupSelectedItems"
              (ngModelChange)="peerGroupOnItemsSelected($event)"
              [itemsObservable]="peerGroupItemsStream"
              [editButtonText]="createViewsModel.peerGroupSelectedText"
              [disabled]="isDataLoading"
              [maxSelectedItems]="4"
              aria-labelledby="peerGroupLabel"
              [ariaLabelValue]="'peer groups'"
            >
            </app-bento-custom-multiselect-overlay>
            <div>
              <ng-template #disabledLink>
                <ng-container *ngIf="isDataLoading">Performance peer groups</ng-container>
              </ng-template>
              <a href="javascript:void(0)" (click)="onClickPPG()" *ngIf="!isDataLoading; else disabledLink">
                Performance peer groups
              </a>
            </div>
          </div>
          <div class="form-group Form-group">
            <label for="practiceGroup" id="practiceGroupLabel">Practice groups</label>
            <app-bento-custom-multiselect-overlay
              name="practiceGroup"
              #practiceGroup
              id="practiceGroup"
              class="FormMultiselect FormMultiselect--extendSmall"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.practiceGroupSelectedItems"
              (ngModelChange)="practiceGroupOnItemsSelected($event)"
              [itemsObservable]="practiceGroupItemsStream"
              [editButtonText]="createViewsModel.practiceGroupSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              [isSelectAllVisible]="false"
              aria-labelledby="practiceGroupLabel"
              [ariaLabelValue]="'practice groups'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group">
            <label for="office" id="officesLabel">Offices</label>
            <app-bento-custom-multiselect-overlay
              name="office"
              #office
              id="office"
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.officeSelectedItems"
              (ngModelChange)="officeOnItemsSelected($event)"
              [itemsObservable]="officeItemsStream"
              [editButtonText]="createViewsModel.officeSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              [isSelectAllVisible]="false"
              aria-labelledby="officesLabel"
              [ariaLabelValue]="'offices'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group">
            <label for="title" id="titlesLabel">Titles</label>
            <app-bento-custom-multiselect-overlay
              name="title"
              id="title"
              #title
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.titleSelectedItems"
              (ngModelChange)="titleOnItemsSelected($event)"
              [itemsObservable]="titleItemsStream"
              [editButtonText]="createViewsModel.titleSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              [isSelectAllVisible]="false"
              aria-labelledby="titlesLabel"
              [ariaLabelValue]="'titles'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group Form-group--indent" *ngIf="isAssociateYear">
            <label for="associate" id="associateExpLabel">Associate experience</label>
            <app-bento-custom-multiselect-overlay
              name="associate"
              id="associate"
              #associateYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.associateYearSelectedItems"
              (ngModelChange)="associateYearOnItemsSelected($event)"
              [itemsObservable]="associateYearItemsStream"
              [editButtonText]="createViewsModel.associateYearSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="associateExpLabel"
              [ariaLabelValue]="'associate experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group Form-group--indent" *ngIf="isSrAssociateYear">
            <label for="srAssociateYear" id="srAssociateExpLabel">Sr Associate experience</label>
            <app-bento-custom-multiselect-overlay
              name="srAssociateYear"
              id="srAssociateYear"
              #srAssociateYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.srAssociateYearSelectedItems"
              (ngModelChange)="srAssociateYearOnItemsSelected($event)"
              [itemsObservable]="srAssociateYearItemsStream"
              [editButtonText]="createViewsModel.srAssociateYearSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="srAssociateExpLabel"
              [ariaLabelValue]="'sr associate experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>
          <div class="form-group Form-group Form-group--indent" *ngIf="isPartnerYear">
            <label for="partnerYear" id="partnerExpLabel">Partner experience</label>
            <app-bento-custom-multiselect-overlay
              name="partnerYear"
              id="partnerYear"
              #partnerYear
              class="FormMultiselect"
              [overlayMaxHeight]="344"
              [ngModel]="createViewsModel.partnerYearSelectedItems"
              (ngModelChange)="partnerYearOnItemsSelected($event)"
              [itemsObservable]="partnerYearItemsStream"
              [editButtonText]="createViewsModel.partnerYearSelectedText"
              [disabled]="disableDropdowns || isDataLoading"
              aria-labelledby="partnerExpLabel"
              [ariaLabelValue]="'partner experience'"
            ></app-bento-custom-multiselect-overlay>
          </div>

          <footer class="FilterPanel-footer Actions">
            <div class="Actions-item">
              <button
                type="button"
                [disabled]="isDataLoading"
                (click)="createView.resetForm(); clearAll()"
                class="btn btn-outline-secondary"
              >
                <span class="btn-text">Clear</span>
              </button>
            </div>
            <div class="Actions-item">
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="createView.invalid || isDataLoading"
                (click)="openSaveView()"
              >
                <span class="btn-text"> Save view </span>
              </button>
            </div>
          </footer>
          <!-- <div class="bento-form-buttons Actions">
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="createView.resetForm(); clearAll()"
                [disabled]="isDataLoading"
              >
                <span class="btn-text">Clear</span>
              </button>

              <button
                type="button"
                class="btn btn-primary"
                [disabled]="createView.invalid || isDataLoading"
                (click)="openSaveView()"
              >
                <span class="btn-text"> Save view </span>
              </button>
            </div> -->
        </div>
      </form>
    </aside>
    <div class="FilterPanel-data FilterPanel--createView chart-container">
      <app-view-data-grid
        #ViewGrid
        class="u-flexGrowCol FilterPanel-grid"
        [userSettingsModel]="createViewsModel"
        [firmBasicData]="firmBasicData"
        [pcViewCols]="pcViewCols"
        [shouldCardDisplay]="true"
        [shouldDisplayPivot]="true"
        [allowViewSettings]="true"
        [showDescription]="true"
        [shouldCheckMetricGroup]="true"
        [isTAdmin]="isTAdmin"
        [fromCreateView]="true"
        [disableExportButton]="createViewForm?.form?.invalid || isDataLoading"
        (dataLoadingStateChange)="dataLoadingStateChange($event)"
        (dragDone)="onDragDone($event)"
        [metricItemsStream]="metricItemsStream"
      ></app-view-data-grid>
    </div>
  </div>
</main>

<app-performance-peer-groups
  class="u-flexGrowCol"
  [createViewModel]="createViewsModel"
  [isCanFirm]="isCanFirm"
  (handleCancelPPG)="retrieveCreateViewSelection($event)"
  *ngIf="openPPG"
></app-performance-peer-groups>
