import {Component} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import { ArrowNavigationService } from 'src/app/shared/arrow-navigation.service';

@Component({
  selector: 'app-pmm-links',
  templateUrl: './pmm-links.component.html',
  styleUrls: ['./pmm-links.component.scss'],
})
export class PmmLinksComponent {
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  pmmVersion :string;

  constructor(private authService: AuthService, private arrowNavigationService: ArrowNavigationService) {
    this.pmmVersion = environment.pmmVersion;
   

  }
  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  launchPMM() {
    var downloadLink = document.createElement('a');
    downloadLink.href = environment.pmmUrl;
    downloadLink.download = environment.pmmFileName;
    downloadLink.target = '_blank';
    downloadLink.click();
  }
  onKeyDown(event: KeyboardEvent) {
    this.arrowNavigationService.handleKeyDown(event);
  }
}
