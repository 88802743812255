<div class="modal-content" [bentoBusyLoader]="isDataLoading">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalShareView" class="modal-header h2">Share view</h3>
  <div class="modal-body">
    <bento-alert [inline]="true" [items]="warningAlerts" *ngIf="showPPPError"></bento-alert>
    <div class="form-group" *ngIf="!showPPPError">
      <label for="shareUser">Select user(s)</label>
      <app-bento-custom-multiselect-overlay
        name="shareUser"
        id="shareUser"
        class="FormMultiselect"
        [ngModel]="shareUserSelectedItems"
        (ngModelChange)="shareUserOnItemsSelected($event)"
        [itemsObservable]="shareUserItemsStream"
        [overlayMaxHeight]="344"
        [editButtonText]="shareUserSelectedText"
        [ariaLabelValue]="'select users'"
      ></app-bento-custom-multiselect-overlay>
    </div>
    <div class="form-group" *ngIf="!showPPPError && !isGeneralUser">
      <bento-checkbox
        id="defaultForUsers"
        [(ngModel)]="isDefaultForAll"
        [disabled]="!(shareUserSelectedItems && shareUserSelectedItems.length > 0)"
      ></bento-checkbox>
      <label for="defaultForUsers" class="font-weight-normal">Make default for selected user(s)</label>
    </div>
  </div>
  <div class="modal-footer a11y">
    <button type="button" 
      class="btn btn-primary" 
      (click)="showPPPError? $event.stopPropagation() : share()" 
      [attr.aria-disabled]="showPPPError? 'true' : 'false' "
      [attr.aria-labelledby]="showPPPError? 'aria-error' : null "

    >Share</button>
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    <span class="sr-only" id="aria-error">Share</span>
  </div>
</div>
